import React from "react";

import { Link } from "gatsby";
import { SEO } from "../components/common/seo";
import { Page } from "../components/Layout/Page";

const PrivacyPage: React.FC = () => (
  <>
    <SEO title="Privacy Policy" />

    <Page>
      <h1>Privacy Policy</h1>
      <p>
        Liminal Space Associates Ltd ("we”) operates life-support.uk (the
        "Site"). This page informs you of our policies regarding the collection,
        use and disclosure of Personal Information we receive from users of the
        Site. We use your Personal Information only for providing and improving
        the Site. By using the Site, you agree to the collection and use of
        information in accordance with this policy.
      </p>

      <h3>Log Data</h3>

      <p>
        Like many site operators, we collect information that your browser sends
        whenever you visit our Site ("Log Data"). This Log Data may include
        information such as your computer's Internet Protocol ("IP") address,
        browser type, browser version, the pages of our Site that you visit, the
        time and date of your visit, the time spent on those pages and other
        statistics. In addition, we use third party services such as Google
        Analytics that collect, monitor and analyze this.
      </p>

      <h3>Cookies</h3>

      <p>
        Cookies are files with small amount of data, which may include an
        anonymous unique identifier. Cookies are sent to your browser from a web
        site and stored on your computer's hard drive. Like many sites, we use
        "cookies" to collect information. You can instruct your browser to
        refuse all cookies or to indicate when a cookie is being sent.
      </p>

      <h3>Security</h3>

      <p>
        We value your trust in providing us your Personal Information, thus we
        are striving to use commercially acceptable means of protecting it. But
        remember that no method of transmission over the internet, or method of
        electronic storage is 100% secure and reliable, and we cannot guarantee
        its absolute security.
      </p>

      <h3>Links to Other Sites</h3>

      <p>
        This Service may contain links to other sites. If you click on a
        third-party link, you will be directed to that site. Note that these
        external sites are not operated by us. Therefore, we strongly advise you
        to review the Privacy Policy of these websites. We have no control over
        and assume no responsibility for the content, privacy policies, or
        practices of any third-party sites or services.
      </p>

      <h3>Changes to This Privacy Policy</h3>

      <p>
        We may update our Privacy Policy from time to time. Thus, you are
        advised to review this page periodically for any changes. We will notify
        you of any changes by posting the new Privacy Policy on this page. These
        changes are effective immediately after they are posted on this page.
      </p>

      <h3>Contact Us</h3>

      <p>
        If you have any questions or suggestions about our Privacy Policy, do
        not hesitate to{" "}
        <a href="mailto:info@the-liminal-space.com?subject=Life Support">
          contact us
        </a>{" "}
        about them.
      </p>
    </Page>
  </>
);

// eslint-disable-next-line import/no-default-export
export default PrivacyPage;
